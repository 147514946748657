import React from 'react'
import { createHashRouter, Navigate } from 'react-router-dom'

import lazy from '@helpers/lazy'
import routes from '@msteams/Routes/routes'
import ContactListLayout from '@pages/Accounting/Contacts/components/ContactListLayout'
import { ProtectedRoute } from '@src/Routes'
import ContactLayout from '@pages/Accounting/Contacts/components/ContactLayout'
import AppLayout from '@msteams/components/AppLayout'
import OnboardingLayout from '@msteams/components/OnboardingLayout'
import TeamLayout from '@pages/Team/components/Layout'

import CostCenterList from '@src/pages/Accounting/CostCenter/List'
import CostCenterForm from '@src/pages/Accounting/CostCenter/Form'
import CostCenterDetails from '@src/pages/Accounting/CostCenter/Details'
import CostCenterDetailsBudget from '@src/pages/Accounting/CostCenter/Details/components/BudgetTab'
import Paywall from '@pages/Paywall'

const ContractsList = lazy(() => import('@src/pages/Accounting/Contracts/List'), 'ContractsList')
const ContractForm = lazy(() => import('@src/pages/Accounting/Contacts/Contracts/ContractForm'), 'ContractForm')
const QuotesList = lazy(() => import('@src/pages/Accounting/Quotes/List'), 'QuotesList')
const QuoteDetails = lazy(() => import('@src/pages/Accounting/Quotes/Details'), 'QuoteDetails')
const VendorsList = lazy(() => import('@src/pages/Accounting/Contacts/ContactsList'), 'VendorsList')
const VendorForm = lazy(() => import('@src/pages/Accounting/Contacts/ContactForm'), 'VendorForm')
const InvoicesList = lazy(() => import('@src/pages/Accounting/Invoices/List'), 'InvoicesList')
const InvoiceDetails = lazy(() => import('@src/pages/Accounting/Invoices/Details'), 'InvoiceDetails')
const PurchaseOrdersList = lazy(() => import('@src/pages/Accounting/PurchaseOrders/List'), 'PurchaseOrdersList')
const PurchaseOrderDetails = lazy(() => import('@src/pages/Accounting/PurchaseOrders/Details'), 'PurchaseOrderDetails')
const VendorContractsList = lazy(
  () => import('@src/pages/Accounting/Contacts/Contracts/ContractsList'),
  'VendorContractsList',
)

const ApprovalRequests = lazy(() => import('@msteams/pages/ApprovalRequests'), 'ApprovalRequests')
const ApprovalRequestDetails = lazy(() => import('@msteams/pages/ApprovalRequestDetails'), 'ApprovalRequestDetails')

const ApprovalRequestsForm = lazy(
  () => import('@src/pages/ApprovalRequests/RequestForm/CustomForm'),
  'ApprovalRequestsForm',
)

const PipelineBuilder = lazy(() => import('@pages/Pipelines/Builder'), 'PipelineBuilder')
const PipelinesList = lazy(() => import('@pages/Pipelines/PipelinesList'), 'PipelinesList')
const ProcessDetails = lazy(() => import('@pages/Pipelines/ProcessDetails'), 'ProcessDetails')
const ProcessesList = lazy(() => import('@pages/Pipelines/ProcessesList'), 'ProcessesList')

const TeamDashboard = lazy(() => import('@msteams/pages/TeamDashboard'), 'TeamDashboard')
const Config = lazy(() => import('@msteams/pages/Config'), 'Config')
const NotSynchronized = lazy(() => import('@msteams/pages/NotSynchronized'), 'NotSynchronized')

const RFQsList = lazy(() => import('@src/pages/Accounting/RFQ/List'), 'RfqList')
const RFQDetails = lazy(() => import('@src/pages/Accounting/RFQ/Details'), 'RfqDetails')
const RFQProposalDetails = lazy(() => import('@src/pages/Accounting/RFQ/ProposalDetails'), 'RfqProposalDetails')

const Auth = lazy(() => import('@msteams/pages/Auth'), 'Auth')
const AuthLayout = lazy(() => import('@msteams/components/AuthLayout'), 'AuthLayout')

const Tasks = lazy(() => import('@pages/Tasks/List'), 'Tasks')

const NotFound = lazy(() => import('@pages/NotFound'), 'NotFound')
const Onboarding = lazy(() => import('@pages/Onboarding'), 'Onboarding')

const SettingsPage = lazy(() => import('@src/pages/Settings'), 'SettingsPage')

const ReportsList = lazy(() => import('@pages/Reports/List'), 'ReportsList')
const AIReports = lazy(() => import('@pages//Reports/List/AIReports'), 'AIReports')
const ManualReports = lazy(() => import('@pages/Reports/List/ManualReports'), 'ManualReports')
const ReportForm = lazy(() => import('@pages/Reports/ReportForm'), 'ReportForm')

const Mailbox = lazy(() => import('@pages/Mailbox'), 'Mailbox')

const OrganizationalStructureList = lazy(() => import('@src/pages/Team/Members/List'), 'OrganizationalStructureList')
const OrganizationalStructureMember = lazy(
  () => import('@src/pages/Team/Members/Member'),
  'OrganizationalStructureMember',
)
const TeamDepartments = lazy(() => import('@src/pages/Team/Departments'), 'TeamDepartments')
const TeamLocations = lazy(() => import('@src/pages/Team/Locations'), 'TeamLocations')

const OrganisationsList = lazy(
  () => import('@src/pages/Accounting/Organizations/OrganizationsList'),
  'OrganisationsList',
)
const OrganizationForm = lazy(() => import('@src/pages/Accounting/Organizations/OrganizationForm'), 'OrganizationForm')

const FinanceSettingsLayout = lazy(
  () => import('@src/pages/Accounting/FinanceSettings/components/Layout'),
  'FinanceSettingsLayout',
)
const AccountsList = lazy(() => import('@src/pages/Accounting/Accounts/AccountsList'), 'AccountsList')
const CategoriesList = lazy(() => import('@src/pages/Accounting/Accounts/CategoriesList'), 'CategoriesList')
const CompanyDetails = lazy(() => import('@src/pages/Accounting/FinanceSettings/CompanyDetails'), 'CompanyDetails')
const TaxList = lazy(() => import('@src/pages/Accounting/Taxes/TaxList'), 'TaxList')
const ProductsList = lazy(() => import('@src/pages/Accounting/Products/List'), 'ProductsList')

const commonRoutes = [
  {
    path: routes.public.auth,
    element: <Auth />,
  },
  {
    path: routes.public.notFound,
    element: <NotFound />,
  },
]

export const personalRouter = createHashRouter([
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        element: <OnboardingLayout />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.tab,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.dashboard,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.approvalRequests,
                element: <ApprovalRequests />,
              },
              {
                path: routes.private.approvalRequestsById,
                element: <ApprovalRequestDetails />,
              },
              {
                path: routes.private.approvalRequestsByIdAction,
                element: <ApprovalRequestDetails />,
              },
              {
                path: routes.private.approvalRequest,
                element: <ApprovalRequestsForm />,
              },
              {
                path: routes.private.approvalRequestEditById,
                element: <ApprovalRequestsForm />,
              },
              {
                path: routes.private.notSynchronized,
                element: <NotSynchronized />,
              },
              {
                path: routes.private.tasks,
                element: <Tasks />,
              },
              {
                path: routes.private.taskBoards,
                element: <Tasks />,
              },

              {
                path: routes.private.purchaseOrders,
                element: <PurchaseOrdersList />,
              },
              {
                path: routes.private.purchaseOrdersById,
                element: <PurchaseOrderDetails />,
              },
              {
                path: routes.private.bills,
                element: <InvoicesList />,
              },
              {
                path: routes.private.billById,
                element: <InvoiceDetails />,
              },

              {
                path: routes.private.invoices,
                element: <InvoicesList />,
              },
              {
                path: routes.private.invoiceById,
                element: <InvoiceDetails />,
              },

              {
                path: routes.private.quotes,
                element: <QuotesList />,
              },

              {
                path: routes.private.quoteById,
                element: <QuoteDetails />,
              },

              {
                element: <ContactListLayout />,
                children: [
                  {
                    path: routes.private.contacts,
                    element: (
                      <ProtectedRoute adminOnly>
                        <VendorsList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.contactsList,
                    element: (
                      <ProtectedRoute adminOnly>
                        <VendorsList />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                element: <ContactLayout />,
                children: [
                  {
                    path: routes.private.contact,
                    element: (
                      <ProtectedRoute adminOnly>
                        <VendorForm />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.contactContracts,
                    element: (
                      <ProtectedRoute adminOnly>
                        <VendorContractsList />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: routes.private.contactContract,
                element: (
                  <ProtectedRoute adminOnly>
                    <ContractForm />
                  </ProtectedRoute>
                ),
              },
              {
                path: routes.private.contact,
                element: (
                  <ProtectedRoute adminOnly>
                    <ContractsList />
                  </ProtectedRoute>
                ),
              },

              { path: routes.private.processes, element: <ProcessesList /> },
              { path: routes.private.pipelineProcesses, element: <ProcessesList /> },
              { path: routes.private.pipelines, element: <PipelinesList /> },
              {
                path: routes.private.pipelineProcessesById,
                element: <ProcessDetails />,
              },
              {
                path: routes.private.pipelineProcesses,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.pipelineApprovalRequest,
                element: <ApprovalRequestsForm />,
              },
              {
                path: routes.private.pipelineById,
                element: (
                  <ProtectedRoute adminOnly>
                    <PipelineBuilder />
                  </ProtectedRoute>
                ),
              },
              {
                path: routes.private.rfqs,
                element: <RFQsList />,
              },
              {
                path: routes.private.rfqProposalsById,
                element: <RFQProposalDetails />,
              },
              {
                path: routes.private.rfqById,
                element: <RFQDetails />,
              },

              {
                path: routes.private.settings,
                element: <SettingsPage />,
                children: [
                  {
                    index: true,
                    path: routes.private.settingsRouted,
                    element: <SettingsPage />,
                  },
                ],
              },

              {
                element: <ReportsList />,
                children: [
                  {
                    path: routes.private.reports,
                    element: <ManualReports />,
                  },
                  {
                    path: routes.private.aiReports,
                    element: (
                      <ProtectedRoute onlyWithReportsAvailable adminOnly>
                        <AIReports />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: routes.private.createReport,
                element: <ReportForm />,
              },
              {
                path: routes.private.reportById,
                element: <ReportForm />,
              },

              {
                path: routes.private.mailbox,
                element: (
                  <ProtectedRoute adminOnly>
                    <Mailbox />
                  </ProtectedRoute>
                ),
              },
              {
                element: <TeamLayout />,
                children: [
                  {
                    path: routes.private.organizationalStructure,
                    element: (
                      <ProtectedRoute checkWithPrivacySettings>
                        <OrganizationalStructureList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.teamDepartments,
                    element: (
                      <ProtectedRoute adminOnly checkWithPrivacySettings>
                        <TeamDepartments />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.teamDepartmentById,
                    element: (
                      <ProtectedRoute adminOnly checkWithPrivacySettings>
                        <TeamDepartments />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.teamLocations,
                    element: (
                      <ProtectedRoute adminOnly checkWithPrivacySettings>
                        <TeamLocations />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.teamLocationById,
                    element: (
                      <ProtectedRoute adminOnly checkWithPrivacySettings>
                        <TeamLocations />
                      </ProtectedRoute>
                    ),
                  },
                ],
              },
              {
                path: routes.private.organizationalStructureById,
                element: (
                  <ProtectedRoute checkWithPrivacySettings>
                    <OrganizationalStructureMember />
                  </ProtectedRoute>
                ),
              },
              {
                path: routes.private.contracts,
                element: (
                  <ProtectedRoute adminOnly>
                    <ContractsList />
                  </ProtectedRoute>
                ),
              },
              {
                path: routes.private.organizations,
                element: (
                  <ProtectedRoute adminOnly onlyWithOrgsAvailable>
                    <OrganisationsList />
                  </ProtectedRoute>
                ),
              },
              {
                path: routes.private.organization,
                element: (
                  <ProtectedRoute adminOnly onlyWithOrgsAvailable>
                    <OrganizationForm />
                  </ProtectedRoute>
                ),
              },
              {
                element: <FinanceSettingsLayout />,
                children: [
                  {
                    path: routes.private.accounts,
                    element: (
                      <ProtectedRoute adminOnly>
                        <AccountsList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.accountById,
                    element: (
                      <ProtectedRoute adminOnly>
                        <AccountsList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.taxes,
                    element: (
                      <ProtectedRoute adminOnly>
                        <TaxList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.taxById,
                    element: (
                      <ProtectedRoute adminOnly>
                        <TaxList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.products,
                    element: (
                      <ProtectedRoute adminOnly>
                        <ProductsList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.productById,
                    element: (
                      <ProtectedRoute adminOnly>
                        <ProductsList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.accountCategories,
                    element: (
                      <ProtectedRoute adminOnly>
                        <CategoriesList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.accountCategoryById,
                    element: (
                      <ProtectedRoute adminOnly>
                        <CategoriesList />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.companyDetails,
                    element: (
                      <ProtectedRoute adminOnly>
                        <CompanyDetails />
                      </ProtectedRoute>
                    ),
                  },
                  {
                    path: routes.private.financeSettings,
                    element: <Navigate to={routes.private.accounts} />,
                  },
                ],
              },
              {
                path: routes.private.costCenter,
                element: (
                  <ProtectedRoute adminOnly>
                    <CostCenterList />
                  </ProtectedRoute>
                ),
              },

              {
                path: routes.private.costCenterById,
                element: (
                  <ProtectedRoute adminOnly>
                    <CostCenterDetails />
                  </ProtectedRoute>
                ),
                children: [
                  {
                    index: true,
                    element: <Navigate to="budget" replace />,
                  },

                  {
                    path: routes.private.costCenterBudget,
                    element: <CostCenterDetailsBudget />,
                  },
                ],
              },
              {
                path: routes.private.costCenterEditById,
                element: (
                  <ProtectedRoute adminOnly>
                    <CostCenterForm />
                  </ProtectedRoute>
                ),
              },
              {
                path: routes.private.paywall,
                element: <Paywall />,
              },
            ],
          },
        ],
      },
      {
        path: routes.private.onboarding,
        element: <Onboarding />,
      },
    ],
  },

  ...commonRoutes,
])

export const teamRouter = createHashRouter([
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        element: <OnboardingLayout />,
        children: [
          {
            element: <AppLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.tab,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.dashboard,
                element: <Navigate to={routes.private.approvalRequests} />,
              },
              {
                path: routes.private.approvalRequests,
                element: <TeamDashboard />,
              },
              {
                path: routes.private.notSynchronized,
                element: <NotSynchronized />,
              },
            ],
          },
        ],
      },
      {
        path: routes.private.onboarding,
        element: <Onboarding />,
      },
    ],
  },
  {
    path: routes.private.config,
    element: <Config />,
  },
  ...commonRoutes,
])
